import { BrowserRouter, Link, Outlet, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import Navbar from "./components/navbar/Navbar";
import Events from "./pages/recently/events/Events";
import Team from "./pages/school/team/Team";
import ParentAssociation from "./pages/school/parentAssociation/ParentAssociation";
import Dates from "./pages/recently/dates/Dates";
import Classes from "./pages/school/classes/Classes";
import Courses from "./pages/offers/courses/Courses";
import Projects from "./pages/offers/projects/Projects";
import AdditionalOffers from "./pages/offers/additional-offers/AdditionalOffers";
import Footer from "./components/footer/Footer";
import PartnerSchool from "./pages/school/partnerSchool/PartnerSchool";
import { ContentProvider } from "./context/ContentProvider";
import Buffet from "./pages/school/buffet/Buffet";
import Downloads from "./pages/recently/downloads/Downloads";

function WithNav() {
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}

function App() {
  return (
    <div id={"app"} className="App">
      <ContentProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<WithNav />}>
              <Route path="/" element={<Home />} />
              <Route path="/events" element={<Events />} />
              <Route path="/dates" element={<Dates />} />
              <Route path="/downloads" element={<Downloads />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/additional-offers" element={<AdditionalOffers />} />
              <Route path="/team" element={<Team />} />
              <Route path="/classes" element={<Classes />} />
              <Route path="/parent-association" element={<ParentAssociation />} />
              <Route path="/partner-school" element={<PartnerSchool />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/buffet" element={<Buffet />} />
              <Route path="*" element={<Page404 />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ContentProvider>
    </div>
  );
}

function Page404() {
  return (
    <div className={"container mt-4 lead"}>
      <h1 className="">404 - Not Found</h1>
      <h2 className="">Seite nicht gefunden</h2>
      <p>Es tut uns leid, die angeforderte Seite wurde nicht gefunden.</p>
      <div className="row w-50">
        <div className="col">
          <Link to={"/"} className={"btn btn-lg btn-outline-primary d-block rounded-0"}>
            Startseite
          </Link>
        </div>
        <div className="col">
          <Link to={"/contact"} className={"btn btn-lg btn-outline-warning d-block rounded-0"}>
            Kontakt
          </Link>
        </div>
      </div>
    </div>
  );
}

export default App;
